
/* 
  Created by no name at 01-06-2021 11:33:40
  Màn hình quản lí bán cho thuê
*/

.highlight::before {
	display: inline-block;
	margin-right: 4px;
	color: #ff4d4f;
	font-size: 14px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: '*';
}
.header_filer {
	justify-content: space-between;
}
.borderBottom {
	border-top: 3px solid black;
	margin: 10px 0px;
}
.quotaRight {
	font-size: 200px;
	font-weight: 100;
	position: absolute;
	right: 0;
	top: -60px;
}
.quotaRightThree {
	font-size: 185px;
	font-weight: 100;
	position: absolute;
	right: 0;
	top: -43px;
}

.ant-upload-list.ant-upload-list-picture-card:hover {
	.ant-upload {
		.ant-upload-select {
			.ant-upload-select-picture-card {
				border-color: #37b5a3;
			}
		}
	}
}

.ant-upload .InPutHover {
	position: absolute;
	bottom: 15px;
	margin-left: -8px;
	opacity: 0;
	width: 102px;
	height: 102px;
}
.InPutHover:hover {
	.ant-upload {
		.ant-upload-select {
			.ant-upload-select-picture-card {
				border-color: #37b5a3;
			}
		}
	}
}
.errlink-open {
	opacity: 1;
	height: auto;
	margin: 5px 0;
	transition: all 1s linear 0.2s;
}
.errlink-hide {
	opacity: 0;
	height: 0;
	transition: all 1s linear 0.6s;
}


