.marginBottom {
  margin-bottom: 10px;
}
.width100 {
  width: 100%;
}
.width100.dFlex {
  display: flex;
  justify-content: center;
}
.margin10 {
  margin: 0 10px;
  cursor: pointer;
}
.inputNumber {
  width: 100% !important;
  height: 46px !important;
}
