.inputChat {
  width: 65px;
  height: 65px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 20px;
  border: 1px solid grey;
  border-radius: 70px;
}
.imageAvatar {
  width: 60px;
  height: 60px;
  border-radius: 60px;
}
.container {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
