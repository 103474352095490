.nameLabel,
.nameLabelNotValid {
  font-weight: normal;
}
.nameLabel::before {
  content: '* ';
  color: red;
}
input {
  height: 40px;
}
