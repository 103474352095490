.marginTop {
  margin-top: 15px;
}
.fontSize20 {
  font-size: 20px;
}
.fontSize18 {
  font-size: 18px;
}
.border-comment {
  border: 1px solid gray;
  padding: 5px;
  margin-left: 65px;
  border-radius: 5px;
}
.view {
  font-size: 20px !important;
}
.ant-comment-actions {
  height: 0;
}
.ant-comment-inner {
  padding: 0 !important;
}

.ant-comment-avatar img {
  width: 55px !important;
  height: 50px !important;
  border-radius: 50%;
  border: 1px solid #8080807a;
  object-fit: contain;
}

.demo-infinite-container {
  border: 1px dashed gray;
  border-radius: 4px;
  overflow: auto;
  background-color: white;
  padding: 10;
  padding: 8px 24px;
  height: 500px;
}
