/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

table.statistic {
    margin-top: 20px;
    width: 100%;
    td {
        padding: 10px;
    }
    th {
        padding: 10px;
    }
}

table.statistic, table.statistic th, table.statistic td {
    border: 1px solid black;
    border-collapse: collapse;
}

.datepicker {
    width: 40%;
}
.apply {
    margin-left: 20px;
    color: #fff;
    background: #37b5a3 !important;
    border-color: #37b5a3 !important;
}
.login-form-button {
    color: #fff;
    background: #37b5a3 !important;
    border-color: #37b5a3 !important;
}