.img-style {
  width: 20px;
  cursor: pointer;
}
// span.ant-upload-picture-card-wrapper {
//   display: flex;
//   justify-content: center;
// }
.borderFooter {
  border: 1px solid #0000006e;
}
.people {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  text-decoration: underline;
  font-style: italic;
  margin: 10px 0px;
}
