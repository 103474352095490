#project_commission {
  border-collapse: collapse;
  width: 100%;
}

#project_commission td,
#project_commission th {
  border: 1px solid #ddd;
  padding: 1px;
  text-align: center;
}

#project_commission th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #6c6dfa73;
  width: 11.6%;
  text-align: center;
}

.borderTop {
  border-top: 2px solid #6c6dfa73;
  margin-top: 1px;
}
.marginNone {
  margin: 0 !important;
}
.marginNone input {
  height: 34px !important;
}
.tdBold {
  font-weight: bold;
}
