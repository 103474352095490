.StyleView2 {
  width: 100%;
  border-bottom: 1px solid #37b5a3;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
.StylesCenter {
  justify-content: center;
  display: flex;
}
.StylesCenter div > label:after {
  content: '';
}
.StylesCenterDrop {
  justify-content: center;
  display: flex;
  width: 100%;
}
.SelectContact {
  margin-top: 20px;
}
.DivNotText {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  height: 48px;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 12px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
