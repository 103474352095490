.area_width {
  margin-right: 10px !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  height: 47px;
}
.absoluteInput {
  text-align: center;
  position: absolute;
  top: -22px;
}
.width100 {
  width: 100% !important;
}
